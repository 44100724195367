import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faTelegram, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { TWITTER, LINKEDIN, TELEGRAM, INSTAGRAM, BROKER_INFO, SIPC_INFO } from '../constants'

import './Footer.css'

export default function Footer() {
    return (
        <footer className="footer montserrat">
            <div className="footer-content">
                <div className="copyright">&copy; 2023 Pints</div>
                <p className="disclaimer">
                    Brokerage services for US-listed, registered
                    securities are offered to self-directed customers. Users of Pints App, have
                    their stocks, ETFs and USD, held in custody by Alpaca Securities LLC, a
                    registered broker-dealer and member of FINRA & SIPC. Additional information
                    about Alpaca Securities LLC can be found{' '}
                    <a href={BROKER_INFO} className="disclaimer-link">
                        here
                    </a>{'. '}
                    Alpaca Securities is a member of the Securities Investor Protection Corporation
                    (SIPC), which protects securities customers of its members up to $500,000
                    (including $250,000 for claims for cash), per customer. Explanatory brochure can
                    be found{' '}
                    <a href={SIPC_INFO} className="disclaimer-link">
                        here
                    </a>{'.'}
                    <br />
                    <br />
                    This is not an offer, solicitation of an offer, or advice to buy or sell
                    securities or open a brokerage account in any jurisdiction where Pints App
                    is not registered.
                </p>
                <div className="footer-bottom">
                    <ul className="footer-links">
                        <li className="footer-link">
                            <a href="/">Home</a>
                        </li>
                        <li className="footer-link">
                            <a href="/blog/about">About</a>
                        </li>
                        <li className="footer-link">
                            <a href={TELEGRAM}>Contact</a>
                        </li>
                    </ul>
                    <div className="socials">
                        <a href={LINKEDIN}>
                            <FontAwesomeIcon icon={faLinkedin} className="social" />
                        </a>
                        <a href={INSTAGRAM}>
                            <FontAwesomeIcon icon={faInstagram} className="social" />
                        </a>
                        <a href={TELEGRAM}>
                            <FontAwesomeIcon icon={faTelegram} className="social" />
                        </a>
                        <a href={TWITTER}>
                            <FontAwesomeIcon icon={faTwitter} className="social" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
