import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'

import logo from '../../Assets/logo.png'

import './Navbar.css'

export default function Navbar() {
    let [hammenuIsOpen, setHammenuIsOpen] = useState(false)

    return (
        <header>
            <nav>
                <a href="/">
                    <img src={logo} alt="logo" className="logo" />
                </a>
                <ul className={`nav-links ${hammenuIsOpen ? 'show-hammenu' : ''}`}>
                    <li className="nav-link montserrat">
                        <a href="/">Home</a>
                    </li>
                    <li className="nav-link montserrat">
                        <a href="/blog">Blog</a>
                    </li>
                </ul>
                <div className="hammenu-btn">
                    <FontAwesomeIcon
                        icon={faBars}
                        className={`open-ham ${hammenuIsOpen ? '' : 'show-img'}`}
                        onClick={() => {
                            setHammenuIsOpen(!hammenuIsOpen)
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faClose}
                        className={`close-ham ${hammenuIsOpen ? 'show-img' : ''}`}
                        onClick={() => {
                            setHammenuIsOpen(!hammenuIsOpen)
                        }}
                    />
                </div>
            </nav>
        </header>
    )
}
