import React from 'react'

import './BottomBanner.css'
import { BannerSubscribeButton } from '../../Components/SubscribeButton/SubscribeButton.jsx'

export default function BottomBanner() {
    return (
        <div className="bottom-banner">
            <div className="information">
                <h4 className="information-header">Be One Of Us</h4>
                <p className="information-para">
                    Get ready to experience an array of unique and exciting events and giveaways
                    when you become part of our community! Unlock all the benefits now.
                </p>
                <BannerSubscribeButton />
            </div>
        </div>
    )
}
