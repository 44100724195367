import React, { useEffect, useState } from 'react'

import Headline from '../../Components/Headline/Headline'
import Features from '../../Components/Features/Features'
import HowItWorks from '../../Components/HowItWorks/HowItWorks'
import BottomBanner from '../../Components/BottomBanner/BottomBanner'

import './Home.css'

export default function Home() {

    return (
        <>
            <div className="home">
                <Headline />
                <Features />
                <HowItWorks />
                <BottomBanner />
            </div>

        </>
    )
}
