import React from 'react'

import './HowItWorks.css'
import trustedAdvisors from '../../Assets/TrustedAdvisors.png'
import purchasePeace from '../../Assets/PurchaseWithPeace.png'
import AuthenticCommunity from '../../Assets/AuthenticCommunity.png'

export default function HowItWorks() {
    return (
        <div className="how-it-works">
            <div className="introduction">
                <h4 className="introduction-header">
                    Why buy stocks when you can get them all in a pint?
                </h4>
                <p className="introduction-para">
                    In just a few easy steps, you can discover stocks, add them to a Pint, punch in
                    the $s and hit “invest”. Now, share your Pint with the wonderful community so
                    they can learn from your research.
                </p>
            </div>

            <div className="special-features">
                <div className="sf-trusted">
                    <div className="sf-trusted-left">
                        <h4 className="sf-header">Trusted Community</h4>
                        <p className="sf-para">
                            Professional financial advisors to help you invest based on your life
                            goals. You can now invest in professionally created Pints. Find, follow
                            and connect with FAs.
                        </p>
                    </div>
                    <img src={trustedAdvisors} alt="trusted-advisors" className="trusted-img" />
                </div>

                <div className="sf-purchase">
                    <img src={purchasePeace} alt="purchase-peace" className="purchase-img" />
                    <div className="sf-purchase-right">
                        <h4 className="sf-header">Invest Securely</h4>
                        <p className="sf-para">
                            Your assets are held by a SEC and FINRA registered broker dealer. As a
                            member of SIPC, your US listed securities are insured up to $250,000 and
                            cash up to $250,000.
                        </p>
                    </div>
                </div>

                <div className="sf-authentic">
                    <div className="sf-authentic-left">
                        <h4 className="sf-header">Authentic Network</h4>
                        <p className="sf-para">
                            Joining our community means more than just having people to chat with;
                            it provides a safe space to curate your ideas. We take pride in having
                            members of all backgrounds coming together to share their insights.{' '}
                        </p>
                    </div>
                    <img src={AuthenticCommunity} alt="auth-community" className="community-img" />
                </div>
            </div>
        </div>
    )
}
