import React, { useState } from 'react'

import './SubscribeInput.css'

export default function SubscribeInput(props) {
    const { onSubmit } = props

    const [emailAddress, setEmailAddress] = useState('')
    const [country, setCountry] = useState('')

    const handleEmailInputChange = event => {
        setEmailAddress(event.target.value)
    }

    const handleCountryInputChange = event => {
        setCountry(event.target.value)
    }

    return (
        <form
            action="https://app.us20.list-manage.com/subscribe/post?u=317e8cb7db97d20b7619cd783&amp;id=a0b1f3567c"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
            onSubmit={onSubmit}
        >
            <div className="register-bar">
                <select
                    type="country"
                    value={country}
                    name="COUNTRY"
                    class="country"
                    className="register-bar-select"
                    id="mce-COUNTRY"
                    placeholder="Singapore"
                    required
                    onChange={handleCountryInputChange}
                >
                    <option value="Singapore" selected>
                        🇸🇬
                    </option>
                    <option value="India">🇮🇳</option>
                    <option value="Philippines">🇵🇭</option>
                    <option value="United Kingdom">🇬🇧</option>
                    <option value="EU">🇪🇺</option>
                    <option value="Others">Others</option>
                </select>
                <input
                    type="email"
                    value={emailAddress}
                    name="EMAIL"
                    class="email"
                    className="register-bar-input"
                    id="mce-EMAIL"
                    placeholder="   Email Address"
                    required
                    onChange={handleEmailInputChange}
                />
                <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
                    <input
                        type="text"
                        name="b_317e8cb7db97d20b7619cd783_a0b1f3567c"
                        tabindex="-1"
                        value=""
                    />
                </div>

                <input
                    type="submit"
                    value="Join"
                    name="join"
                    id="mc-embedded-subscribe"
                    class="button"
                    className="register"
                />
            </div>
            <input
                type="submit"
                value="Join"
                name="join"
                id="mc-embedded-subscribe"
                class="button"
                className="center-register"
            />
        </form>
    )
}
