import React from 'react'

import fractionImg from '../../Assets/FractionCircle.png'
import friendsImg from '../../Assets/FriendsCircle.png'
import funImg from '../../Assets/FunCircle.png'
import oneClickInvestImg from '../../Assets/OneClickInvest.png'
import introVideo from '../../Assets/Intro_Pints.mp4'
import videoPoster from '../../Assets/VideoPoster.png'

import './Features.css'

export default function Features() {
    return (
        <div className="features">
            <div className=" features features-content ">
                <div className="features-list ">
                    <div className="feature-fraction ">
                        <div className="feature-top">
                            <img src={fractionImg} alt="fraction" className="feature-img" />
                        </div>
                        <div className="feature-bottom">
                            <h4 className="feature-heading-fraction">Fraction</h4>
                            <div className="feature-para-div">
                                <p className="feature-para">
                                    Investing in your dream stocks is now more achievable than ever!
                                    With our fractional approach, you can get started with just $10.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="feature-friends">
                        <div className="feature-top">
                            <img src={friendsImg} alt="friends" className="feature-img" />
                        </div>
                        <div className="feature-bottom">
                            <h4 className="feature-heading-friends">AI</h4>
                            <div className="feature-para-div">
                                <p className="feature-para">
                                    Have an investing AI co-pilot by your side. Ask any questions,
                                    and get answers 24/7! No more guessing and the dark with your
                                    investment decisions.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="feature-fun">
                        <div className="feature-top">
                            <img src={funImg} alt="fun" className="feature-img" />
                        </div>
                        <div className="feature-bottom">
                            <h4 className="feature-heading-fun">Social</h4>
                            <div className="feature-para-div">
                                <p className="feature-para">
                                    Go Social - learn from peers and certified advisors. Connect and
                                    develop an informed perspective—all within our community!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="one-click-invest">
                    <div className="one-click-invest-left">
                        <h3 className="one-click-heading">One Click Invest</h3>
                        <p className="one-click-para">
                            Bunch your favorite stocks into a 'Pint' and buy them all at once - with
                            the 1Click invest feature. We split the money and buy the fractions of
                            your chosen stocks for you. You can create an automated systematic
                            investment plan for your Pints by choosing to invest weekly or monthly.
                        </p>
                    </div>
                    <img src={oneClickInvestImg} alt="one-click" className="one-click-img" />
                </div>
                <video controls className="video-element" preload="none" poster={videoPoster}>
                    <source src={introVideo} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
