import React from 'react'

import HeadlineImg from '../../Assets/HeadlineImg.png'

import { HeaderSubscribeButton } from '../../Components/SubscribeButton/SubscribeButton.jsx'

import './Headline.css'

export default function Headline() {
    return (
        <div className="headline-overview">
            <div className="headline-left">
                <h1 className="home-heading  ">
                    Investing just got a whole
                    <br /> lot friendlier with AI
                </h1>
                <p className="headline-subheading ">
                    Explore a brand new way to start investing - together with your friends and AI.
                    Watch, learn, and discover investing strategies to make investing easy.
                </p>
                <HeaderSubscribeButton />
            </div>
            <div className="headline-right">
                <img src={HeadlineImg} alt="headline-img" />
            </div>
        </div>
    )
}
