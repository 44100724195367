import React from 'react'
import Navbar from './Layout/Navbar/Navbar'
import Home from './Pages/Home/Home'
import Footer from './Layout/Footer/Footer'
import ThankYou from './Components/ThankYou/ThankYou'

import { install } from 'ga-gtag'

// Substitute your tracking ID (begins with "G-", "UA-", "AW-" or "DC-")
install('G-8GB2FSCMMY')

function App() {
    let Component
    switch (window.location.pathname) {
        case "/":
            Component = Home
            break
        case "/confirmation":
            Component = ThankYou
            break
        default:
            Component= Home
            break
    }

    return (
        <>
            <Navbar />
            <Component/>
            <Footer />
        </>
    )
}

export default App
