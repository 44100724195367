import { React, useState } from 'react'

import './SubscribeButton.css'
import SubImg from '../../Assets/Subscribe-form.png'
import SubscribeInput from '../SubscribeInput/SubscribeInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { useRef } from 'react'

export function HeaderSubscribeButton() {
    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleOnSubmit = event => {
        event.preventDefault()
        handleCloseModal()
        window.location.href = '/confirmation'
    }

    let modalRef = useRef()

    const closeModelFromOutClick = event => {
        if (!modalRef.current.contains(event.target)) {
            handleCloseModal()
        }
    }

    return (
        <div>
            <button type="button" className="subscribe-button button1" onClick={handleOpenModal}>
                Join the Waitlist Here
            </button>
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModelFromOutClick}>
                    <div className="modal-content" ref={modalRef}>
                        <button onClick={handleCloseModal} className="close-button">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <h3 className="subscribe-header">Be Part of Us!</h3>
                        <p className="subscriber-para">
                            Join our waitlist to get first dibs into our app
                        </p>
                        <img src={SubImg} alt="sub-img" className="sub-img" />
                        <SubscribeInput onSubmit={handleOnSubmit} />
                    </div>
                </div>
            )}
        </div>
    )
}

export function BannerSubscribeButton() {
    const [isModalOpen, setModalOpen] = useState(false)

    const handleOpenModal = () => {
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    const handleOnSubmit = event => {
        event.preventDefault()
        handleCloseModal()
        window.location.href = '/confirmation'
    }

    let modalRef = useRef()

    const closeModelFromOutClick = event => {
        if (!modalRef.current.contains(event.target)) {
            handleCloseModal()
        }
    }

    return (
        <div>
            <button type="button" className="subscribe-button button2" onClick={handleOpenModal}>
                Join the Tribe - Waitlist here
            </button>
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModelFromOutClick}>
                    <div className="modal-content" ref={modalRef}>
                        <button onClick={handleCloseModal} className="close-button">
                            <FontAwesomeIcon icon={faXmark} />
                        </button>
                        <h3 className="subscribe-header">Be Part of Us!</h3>
                        <p className="subscriber-para">
                            Join our waitlist to get first dibs into our app
                        </p>
                        <img src={SubImg} alt="sub-img" className="sub-img" />
                        <SubscribeInput onSubmit={handleOnSubmit} />
                    </div>
                </div>
            )}
        </div>
    )
}
