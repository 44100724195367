import React from 'react'

import ThankYouImg from '../../Assets/ThankYou.png'
import hand from '../../Assets/hand.png'
import googleStore from '../../Assets/GoogPlaystore.png'
import appleStore from '../../Assets/Applestore.png'
import { APPLE_STORE, GOOGLE_PLAY_STORE } from '../constants'

import './ThankYou.css'

export default function ThankYou() {
    const copyToClipboard = () => {
        var copyText = document.getElementById('ref-number')

        copyText.select()
        copyText.setSelectionRange(0, 99999)

        navigator.clipboard.writeText(copyText.value)
        var copyBtn = document.getElementById('copy-btn')
        copyBtn.value = 'Copied!'
        const setValue = () => copyBtn.value === 'Copy' ? copyBtn.value = 'Copied!' : copyBtn.value = 'Copy'
        setTimeout(setValue, 800)
    }

    return (
        <div className="thank-you">
            <picture className="thank-you-img">
                <source media="(max-width: 480px)" srcset={hand} />
                <img src={ThankYouImg} alt="ThankYou" className="main-img" />
            </picture>
            <div className="icon-bar">
                <a href={APPLE_STORE}>
                    <img src={googleStore} alt="google-store" className="download" />
                </a>
                <div className="icon-gap" />
                <a href={GOOGLE_PLAY_STORE}>
                    <img src={appleStore} alt="apple-store" className="download" />
                </a>
            </div>
            <div className="reference-bar">
                <input
                    type="text"
                    value="D36A0"
                    id="ref-number"
                    className="text-box"
                    disabled
                />
                <input type='button' className="copy-btn" value="Copy" id='copy-btn' onClick={copyToClipboard} />
            </div>

            <text className='reminder'>
                Remember to key in your unique referral code!
            </text>
            <h1 className="thank-you-heading">Thank you!</h1>
            <p className="thank-you-para">
                We thank you very much for your interest, you will receive an email in a short
                while, and do follow up closely, you never know when the surprise will arrive 👀
            </p>
        </div>
    )
}
